a-participant-who-has-input-fields-must-have-sign-off-action: A participant who has input fields must have "Sign Off" action
acknowledged: Acknowledged
acknowledgment: Acknowledgment
access: Access
add-saved-instruction: Add Saved Instruction(s)
save-to-instructions-library: Save to Instructions Library
saved-instructions:  Saved Instructions
instruction-title: Instruction Title
save-as-instruction-template: Save Instruction Template
save-to-clause-library: Save to Clause Library
clause:
  add: Add Clause
  save: Save Clause
access-granted-by: Access granted by
action: Action
actions: Actions
active: Active
active-documents-number: Active Documents Number
add-another-radio-button: Add Another Radio Button
add-autogenerated-date-field: Add autogenerated date field
add-autogenerated-name-field: Add autogenerated name field
available-templates-forms: Available Templates (Forms)
available-forms: Available Forms
remove-autogenerated-date-field: Remove autogenerated date field
remove-autogenerated-name-field: Remove autogenerated name field
add-color: Add Color
add-comment: Add Comment
add-date-field: Add Date Field
add-name-field: Add Name Field
add-initials: Add Initials
add-myself: Add Myself
add-new: Add new
add-role: Add Role
add-signature: Add Signature
add-signer: Add Signer
add-signers-and-setup-parameters: Add signers and setup parameters
add-to-association-library: Add to association library
add-to-broker-library: Add to broker library
add-to-mls-library: Add to mls library
add-to-stage: Add to Stage
agent-name: Agent Name
system-user-acknowledgement: System User Acknowledgement
system-user-acknowledgement-declined: System User Acknowledgement Declined
Participant-Acknowledgement-Declined: Participant Acknowledgement Declined
has-been-notified-of-your-election-however-if-you-selected-declined-inadvertently-you-may-re-access-the-eSigning-by-clicking-open-document-in-your-eSigning-invitation: has been notified of your election, however if you selected "Decline" inadvertently you may re-access the eSigning by clicking "Open Document(s)" in your eSigning invitation
if-you-selected-decline-inadvertently-you-may-re-access-the-acknowledgement-by-refreshing-your-browser-or-logging-back-in-via-smart-MLS: If you selected "Decline" inadvertently you may re-access the Acknowledgement by refreshing your browser or logging back in via SmartMLS
if-you-have-any-questions-please-contact-support-at-SmartMLS-code: If you have any questions please contact support at SmartMLS ({code})
by-electing-to-continue-you-acknowledge-you-have-read-the-smart-mls-sign: By electing to "Continue", you acknowledge you have read the SmartMLS Sign
agree-to-the-terms-contained-therein: agree to the terms contained therein
terms-and-conditions: Terms and Conditions
privacy-policy: Privacy Policy
internal: Internal
internal-administrative: Internal/Administrative
all-availability: All Availability
all-contacts: All Contacts
all-participations-should-have-unique-roles: All participants should have unique roles
all-participants-must-have-unique-email: All participants must have unique email
any: Any
please-provide-a-reason-for-declining-the-documents-in-the-text-field-below-and-click-confirm-or-click-cancel-to-return-to-the-documents: Please provide a reason for declining the document(s) in the text field below and click Confirm or click Cancel to return to the document(s)
are-you-sure-you-want-to-delete-contact-name: Are you sure you want to delete contact "{name}"?
are-you-sure-you-want-to-restore-contact-name: Are you sure you want to restore contact "{name}"?
are-you-sure-you-want-to-permanently-delete-contact-name: Are you sure you want to permanently delete contact "{name}"?
are-you-sure-you-want-to-delete-document-name: Are you sure you want to delete document "{name}"?
are-you-sure-you-want-to-permanently-delete-clause-name: Are you sure you want to permanently delete clause "{name}"?
are-you-sure-you-want-to-delete-template: Are you sure you want to delete template "{name}"?
are-you-sure-you-want-to-restore-group-name: Are you sure you want to restore group "{name}"?
are-you-sure-you-want-to-delete-group-name: Are you sure you want to delete group "{name}"?
are-you-sure-you-want-to-permanently-delete-instruction-name: Are you sure you want to permanently delete instruction "{name}"?
are-you-sure-you-want-to-permanently-delete-group-name: Are you sure you want to permanently delete group "{name}"?
are-you-sure-you-want-to-delete-selected-signings: Are you sure you want to delete selected signings?
are-you-sure-you-want-to-delete-selected-contacts: Are you sure you want to delete selected contacts?
are-you-sure-you-want-to-permanently-delete-selected-contacts: Are you sure you want to permanently delete selected contacts?
are-you-sure-you-want-to-delete-selected-groups: Are you sure you want to delete selected groups?
are-you-sure-you-want-to-permanently-delete-selected-signings: Are you sure you want to permanently delete selected signings?
are-you-sure-you-want-to-delete-selected-templates: Are you sure you want to delete selected templates?
are-you-sure-you-want-to-delete-signing-name: Are you sure you want to delete signing "{name}"?
are-you-sure-you-want-to-permanently-delete-signing-name: Are you sure you want to permanently delete signing "{name}"?
are-you-sure-you-want-to-delete-stage-name: Are you sure you want to delete stage "{name}"?
are-you-sure-you-want-to-restore-template-name: Are you sure you want to restore template "{name}"?
are-you-sure-you-want-to-delete-template-name: Are you sure you want to delete template "{name}"?
are-you-sure-you-want-to-permanently-delete-template-name: Are you sure you want to permanently delete template "{name}"?
are-you-sure-you-want-to-duplicate-signing-name: Are you sure you want to duplicate signing "{name}"?
are-you-sure-you-want-to-duplicate-template-name: Are you sure you want to duplicate template "{name}"?
are-you-sure-you-want-to-leave-without-saving: Are you sure you want to leave without saving?
are-you-sure-you-want-to-withdraw-signing-name: Are you sure you want to withdraw signing "{name}"?
are-you-sure-you-want-to-delete-page: Are you sure you want to delete this page?

association: Association
audit-trail-chronology-view: Audit Trail - Chronology View
auth:
  to-sign-in: Sign In
  to-sign-out: Sign Out
autofill: Autofill
availability: Availability
back: Back
birthday: Birthday
broker: Broker
browse: Browse
can-not-send-signing-with-only-recipient:  Can't send signing with only a recipient
cancel: Cancel
clear: Clear
close: Close
center: Center
change: Change
checkbox: Checkbox
chronology: Chronology
clear-document-fields: Clear Document Fields
fields-and-roles: Fields and Roles
color: Color
header-background-color: Header background color
font-color: Header font color
comment: Comment
commented-by: Commented By
common-instructions: Common Instructions
completed: Completed
configure: Configure
consent-given: Consent given
contact-description: Contact Description
contact-groups: Contact Groups
contacts: Contacts
contacts-count: No Contacts | {count} Contact | {count} Contacts
continue: Continue
create: Create
create-and-start-signing: Create and Start Signing
create-and-use: Create and Use
create-document-template: Create Document Template
create-draft: Create Draft
create-signing: Create Signing
create-signature: Create Signature
created: Created
created-on: Created on
created-by: Created By
creation-date: Creation Date
creator: Creator
current: Current
current-stage: Current Stage
current-stage-participants: Current Stage Participants
widget: Widget
date: Date
date-withdrawn: Date Withdrawn
date-edited: Date Edited
signing-has-been-edited: Signing has been edited
date-time: Date Time
declination-date: Declination Date
decline-document: Decline Document
decline-documents: Decline Document(s)
declined: Declined
delete: Delete
deleted: Deleted
delete-contact: Delete Contact
delete-page: Delete page
rotate-page: Rotate page
permanently-delete-instruction: Permanently Delete Instruction
permanently-delete-contact: Permanently Delete Contact
delete-document: Delete Document
delete-group: Delete Group
permanently-delete-group: Permanently Delete Group
delete-signing: Delete Signing
delete-signings: Delete Signings
delete-stage: Delete Stage
delete-template: Delete Template
delivery: Delivery
description: Description
details: Details
directory: Directory
directory-search: Directory search
directory-search-tooltip: Please enter Personal, Association name, Firm name Office name, Team name OR Association code, Firm code, Office code, Team code.
distribute: Distribute
distributed: Distributed
distributor: Distributor
distribution-party: Distribution Party
add-distributor: Add Distributor
add-recipient: Add Recipient
edit-recipient: Edit Recipient
do-you-want-to-save-these-contacts-as-a-signing-group-for-future-signings: Do you want to save these contacts as a signing group for future signings?
do-you-want-to-update-the-contact-group: Do you want to update the contact group?
document: Document
document-downloaded: Document downloaded
document-has-been-sent-successfully: Document has been sent successfully
document-is-not-generated-yet-please-wait-a-few-minutes: Document is not generated yet. Please wait a few minutes
document-link-clicked-in-the-email: Document link clicked in the email
document-name: Document Name
document-opened: Document opened
document-opened-on-desktop: Document opened on desktop
document-opened-on-mobile: Document opened on mobile
document-signed-off: Document signed off
document-review-approved: Document review approved
document-template: Document Template
document-templates: Document Templates
document-templates-details: Document Templates Details
document-was-declined: Document was declined
document-was-withdrawn: Document was withdrawn
document-withdrawn: Document withdrawn
rename-document: Rename Document
documents: Documents
documents-has-been-sent-successfully: Documents has been sent successfully
dont-ask-any-more: Don't ask any more
download: Download
download-pdf: Download PDF
pdf: PDF
drafts: Drafts
draw-signature: Draw Signature
drop-broker-logo-image-file-here-or: Drop broker logo image file here or
drop-image-file-here-or: Drop image file here or
drop-file-here-or: Drop file here or
drop-files-here-or: Drop files here or
due-date: Due Date
duplicate: Duplicate
duplicate-signing: Duplicate Signing
duplicate-template: Duplicate Template
edit: Edit
add-contact: Add Contact
edit-contact: Edit Contact
edit-document: Replace Document
edit-document-template: Edit Document Template
edit-group: Edit Group
edit-participant-email: Edit participant email
edit-participant-phone: Edit participant phone
edit-signing: Edit Signing
edit-stage: Edit Stage
editable: Editable
add-documents: Add Document(s)
add-stage: Add Stage
editor: Editor
markup: Markup
drawing: Drawing
rectangle: Rectangle
ellipse: Ellipse
circle: Circle
line: Line
arrow: Arrow
freehand: Freehand
participant-actions: Participant Actions
email: Email
email-appearance: Email Appearance
email-delivered-to-address: Email delivered to "{address}"
email-sent-to-address: Email sent to "{address}"
signing-was-forwarded-to: Signing was forwarded to {forwardedTo}
link-clicked-in-the-email-sent-to-address: Link clicked in the email sent to "{address}"
email-sent-to-address-read: Email sent to "{address}" read
email-sent-to-address-blocked: Email sent to "{address}" blocked
email-sent-to-address-bounced: Email sent to "{address}" bounced
email-sent-to-address-dropped: Email sent to "{address}" dropped
email-sent-to-address-was-marked-as-spam: Email sent to "{address}" was marked as Spam
the-signing-email-could-not-be-delivered-to-address: The signing email could not be delivered to "{address}"
if-the-email-address-is-incorrect-please-use-the-edit-button-on-the-signing-details-tab-to-correct-the-recipients-email-address-and-resend-the-signing: If the email address is incorrect, please use the Edit button on the Signing Details tab to correct the recipient's email address and resend the signing
if-you-need-further-assistance-please-contact-support: If you need further assistance, please contact support
email-read: Email read
email-sent: Email Sent
emails: Emails
empty-document: Empty Document
end-date: End Date
enter-url: Enter URL...
error: Error
errors:
  an-error-has-occurred: An error has occurred
event: Event
every-number-days: No Days | {count} Day | {count} Days
exceeded-the-maximum-file-size-of-size: Exceeded the maximum file size of {size}
expiration-date: Expiration Date
expired: Expired
file-format-not-supported: File format not supported
filter: Filter
first-name: First Name
firm: Firm
font: Font
font-size: Font Size
for-me-only: For me only
formula-error-no-field-found: No field found named {field}
formula-is-invalid: Formula is invalid
format: Format
forms-and-templates: Templates (Forms)
forward: Forward
forward-document: Forward Document
fully-executed: Fully Executed
full-name: Full Name
go-home: Go home
group-name: Group Name
groups: Groups
groups-count: No Groups | {count} Group | {count} Groups
highlight: Highlight
i-acknowledge-and-would-like-to-read-the-document: I acknowledge and would like to read the document
i-do-not-accept: I Do Not Accept
in-progress: In Progress
individuals: Individuals
initials: Initials
instructions: Instructions
interval-for-the-reminders: Interval for the reminder emails
it-is-possible-to-download-only-one-file: It is possible to download only one file
label: Label
large: Large
last-action-date: Last Action Date
last-name: Last Name
last-updated: Last Updated
last-updated-by: Last Updated By
last-revised: Last Revised
last-used: Last Used
leave-without-saving: Leave Without Saving
left: Left
list-is-empty: List is empty
library: Library
link-loop-found: |
  Reference cycle found: {loop}
load: Load
load-contact-group: Load Contact Group
load-from-url: Load from URL
long: Long
manage: Manage
manage-templates: Manage Templates
mandatory-field: Mandatory Field
medium: Medium
members: Members
message: Message
mls-id: MLS ID
move-left: Move Left
move-right: Move Right
name: Name
new: New
new-stage: New Stage
new-signing: New Signing
new-template: New Template
new-contact: New Contact
new-group: New Group
next: Next
next-field: Next Field
next-month: Next Month
page: Page
next-page: Next Page
next-year: Next Year
'no': 'No'
no-events: No Events
no-files-provided: No files provided
not-set: Not Set
not-started: Not Started
notes: Notes
nothing-found: Nothing Found
no-contacts-found: No contacts found
nothing-found-continue-typing-to-see-more-results: Nothing found. Continue typing to see more results...
notification: Notification
notifications: Notifications
number-of-fields-have-missing-parameters: fields have missing parameters
number-out-of-total: '{number} out of {total}'
of: of
office: Office
ok: Ok
one-or-more-documents-were-not-uploaded: One or more documents were not uploaded
open-editor: Open Editor
optional: Optional
overlay-settings:
  checkbox:
    checkbox: Checkbox Settings
    radio: Radio Settings
  date: Date Settings
  signature:
    initials: Initials Settings
    signature: Signature Settings
  text:
    email: Email Settings
    name: Name Settings
    text: Text Settings
page-does-not-exist: Page does not exist
access-forbidden: Access forbidden
page-number: Page {number}
participants: Participants
end-user-acknowledgement-participants: End User Acknowledgement (Participants)
consumer-disclosure: Consumer Disclosure
participant-acknowledgement-accepted: Participant acknowledgement accepted
participant-acknowledgement-declined: Participant acknowledgement declined
pending: Pending
permanently-delete-signing: Permanently Delete Signing
permanently-delete-signings: Permanently Delete Signings
permanently-delete-number-selected: Permanently Delete {number} Selected
permanently-delete-only-this-signing: Permanently Delete Only This Signing
permission-type-mls: SmartMLS
permission-type-association: Association
permission-type-personal: Personal
permission-type-firm: Firm
permission-type-office: Office
permission-type-team: Team
person: Person
personal: Personal
phone: Phone
phone-is-invalid: Phone is invalid
please-add-at-least-one-document: Please add at least one document
please-select-at-least-one-document: Please select at least one document
please-check-one-of-these-radios: Please select one of these options
please-check-this-checkbox: Please check this checkbox
please-enter: Please enter
please-enter-a-name-and-the-expiring-date: Please enter a name and the expiring date
please-enter-a-template-name: Please enter a template name
please-enter-date: Please enter date
please-enter-participant-email: Please enter participant email
please-enter-participant-phone: Please enter participant phone
phone-number-is-required-when-two-or-more-participants-have-the-same-email-address: >-
  Phone number is required when two or more participants have the same email
  address
please-enter-participant-name: Please enter participant name
please-enter-participant-last-name: Please enter participant last name
please-enter-radio-group: Please enter radio group
please-enter-comment: Please enter comment
please-enter-role: Please enter role
please-enter-stage-name: Please enter stage name
please-enter-the-document-name: Please enter the document name
please-enter-the-signing-name: Please enter the signing name
please-enter-the-template-name: Please enter the template name
please-enter-expiration-date: Please enter the expiration date
please-fix-the-issues-marked-with-exclamation-mark: Please fix the issues marked with exclamation mark
please-select-more-than-one-field: Please select more than one field
please-upload-a-document-to-sign-or-select-a-template: Please upload a document to sign or select a template
please-wait-for-the-file-to-be-processed: Please wait for the file to be processed
please-wait-for-the-files-to-be-processed-number-of-total: Please wait for the files to be processed ({number} / {total})
prev: Prev
preview: Preview
previous-field: Previous Field
previous-month: Previous Month
previous-page: Previous Page
previous-year: Previous Year
private: Private
processing: Processing
progress: Progress
property-address: Property Address
provide-role-and-radio-group-to-use-button: Provide role and radio group to use button
provide-role-to-use-button: Provide role to use button
provide-role-to-add-date: Provide role to add date
provide-role-to-add-name: Provide role to add name
public: Public
qty-documents: Qty Documents
radio: Radio
radio-group: Radio Group
radio-group-is-already-associated-different-role: Radio Group is already associated different role
read: Read
ready: Ready
reason: Reason
received: Received
recycling-bin: Recycling Bin
reject: Reject
reminder: Reminder
reminders-time: Reminder time
remove: Remove
repeat: Repeat
resend-invitation: Resend invitation
resend-to-this-participant: Resend to this Participant
resend-signing: Resend signing
review: Review
reviewer: Reviewer
reviews: Reviews
rich-text:
  bold: Bold
  bullet-list: Bullet list
  heading-number: Heading {number}
  italic: Italic
  link: Link
  ordered-list: Ordered list
  strikethrough: Strikethrough
right: Right
role: Role
role-action: Role Action
role-description: Role Description
roles: Roles
sample-text: Sample text
save: Save
save-and-resend-signing: Save and resend signing
save-and-start-signing: Save and Start Signing
save-and-use: Save and Use
save-contact-group: Save Contact Group
save-default-settings: Save Default Settings
save-draft: Save Draft
save-as-draft: Save as Draft
save-groups: Save Groups
saved: Saved
scale: Scale
search: Search
select:
  list-is-empty: List is empty
  nothing-found: Nothing found
  number-more: '{number} more...'
  type-to-search: Type to search
select-document: Select Document
select-event: Select Event
select-group: Select group
select-group-or-contact: Select group or contact
search-signings-and-contacts: Search signings and contacts
select-person: Select person
select-template: Select Template
selected-contacts: Selected Contacts
selected-count-of-total: Selected {count} of {total}
send: Send
send-date: Send Date
send-document: Send Document
send-signing: Send Signing
settings: Settings
short: Short
show-deleted: Show Deleted
display-dollar: Display $
display-percentage: Display %
sign:
  email: Email
  name: Name
  or-sign-in-with-email: Or sign in with email
  password: Password
  password-validation:
    must-be-at-least-8-characters-long: Password must be at least 8 characters long
    must-have-digits: Password must have digits
    must-have-lowercase-characters: Password must have lowercase characters
    must-have-only-latin-characters: Password must have only latin characters
    must-have-special-characters: Password must have special characters
    must-have-uppercase-characters: Password must have uppercase characters
  phone: Phone
  pick-password: Pick a password
  sign-in-process: Sign in
  sign-in-with: Sign in with
  sign-up-process: Sign up
  surname: Surname
  to-sign-in: Sign in
  to-sign-out: Sign out
  to-sign-up: Sign up
  to-sign-with-google: Sign in with Google
  to-sign-with-ms: Sign in with Microsoft
  to-sign-with-smart-mls: Sign in with Smart MLS
sign-document: Sign Document
sign-off: Sign Off
sign-off-rejected: Sign off rejected
signature: Signature
signed: Signed
signer: Signer
signer-action: Signer action
signers: Signers
signing: Signing
signing-details: Signing Details
signing-does-not-have-any-documents: Signing does not have any documents
signing-flow: Signing Flow
signing-is-started: Signing Is Started
signing-name: Signing Name
signing-order: Signing Order
signing-progress: Signing progress
signing-started: Signing started
signing-started-over: Signing started over
signing-was-withdrawn: Signing was withdrawn
signing-edited: Signing edited
signings: Signings
signings-count: No Signings | {count} Signing | {count} Signings
signoff: Sign off
size:
  b: B
  gb: GB
  kb: KB
  mb: MB
  tb: TB
small: Small
smart-filters: Smart Filters
smart-mls: SmartMLS
sms-notification-sent: SMS notification sent
special-access: Special Access
special-access-view: Special Access Dashboard
stage: Stage
stage-end-date: Stage End Date
stage-instructions: Stage Instructions
stage-number: Stage {number}
stage-name: Stage Name
stage-started: Stage started
stages: Stages
start-signing: Start Signing
started: Started
status: Status
strict: Strict
strikethrough: Strikethrough
successfully-saved: Successfully saved
supported-only-images: Supported only images
supported-only-image-files: Supported only image files
supported-format-list: >-
  Supported file formats: .doc, .docx, .xls, .xlsx, .bmp, .gif, .jpg, .tif,
  .pdf, .jpeg, .rtf, .odt, .ods, .heic, .heif
team: Team
template: Template
template-name: Template Name
template-settings: Template Settings
templates: Templates
templates-count: No Templates | {count} Template | {count} Templates
instructions-count: No Instructions | {count} Instruction | {count} Instructions
text: Text
text-alignment: Text Alignment
text-box: Text Box
text-color: Text Color
text-line: Text Line
thank-you: Thank you!
the-selected-group-does-not-contain-contacts: The selected group does not contain contacts
there-are-errors-in-the-signing-flow: There are errors in the signing flow
thickness: Thickness
this-contact-is-not-editable: This contact is not editable
this-group-can-not-be-edited: This group can not be edited
this-role-should-be-in-the-signing-flow: This role should be in the signing flow
this-role-must-be-added-to-a-stage-use-the-add-to-stage-button-in-the-field-settings-to-add-this-role-to-a-new-or-existing-stage: This role must be added to a stage. Use the "Add to Stage" button in the field settings to add this role to a new or existing stage.
this-signing-cannot-be-edited: This signing cannot be edited
this-signing-cannot-be-withdrawn: This signing cannot be withdrawn
move-to-completed: Mark as Completed
this-signing-cannot-be-completed: This signing cannot be completed
this-template-can-not-be-deleted: This template can not be deleted
this-template-cannot-be-edited: This template cannot be edited
time: Time
time-stamp: Time Stamp
to: to
to-bottom: To Bottom
to-clear: Clear
to-close: Close
to-collapse: Collapse
to-confirm: Confirm
to-decline: Decline
to-delete: Delete
to-expand: Expand
to-left: To Left
to-permanently-delete: Permanently Delete
to-right: To Right
to-select: Select
to-sign-out: Sign Out
to-top: To Top
to-use: Use
toggled: Toggled
transparency: Transparency
type: Type
select-font: Select Font
type-in-your-name: Type in your name
updated-on: Updated On
upload-documents: Upload Document(s)
upload-file: Upload File
upload-signature: Upload Signature
uploaded: Uploaded
use-group: Use Group
use-members-and-roles: Use Members and Roles
user: User
user-menu: User Menu
validation:
  please-put-your-initials: Please put your initials
  please-put-your-signature: Please put your signature
  please-select-participant-or-enter-details: Please select participant or enter details
  please-select-something: Please select something
  please-type-something: Please type something
  please-enter-a-number: Please enter a number
  please-enter-email: Please enter email address
  please-enter-phone: Please enter phone number
  please-select-date: Please select date
  wrong-email-format: Wrong email format
  wrong-phone-format: Wrong phone format
  wrong-number-format: Wrong number format
  please-add-at-least-one-field-to-sign: Please add at least one field to sign
  please-select-option: Please select option
view: View
withdraw: Withdraw
withdraw-document: Withdraw Document
withdraw-signing: Withdraw Signing
withdrawn: Withdrawn
'yes': 'Yes'
you-need-to-draw-your-signature: You need to draw your signature
you-need-to-select-font: You need to select font
you-need-to-type-you-name: You need to type you name
your-instructions: Your Instructions
zoom-in: Zoom In
zoom-out: Zoom Out
participant: Participant
middle-name: Middle Name
stage-completed: Stage Completed
reviewed: Reviewed
replace-document: Replace Document
apply-template: Apply Template
select-template-document: Select Template Document
clear-fields: Clear Fields
do-you-want-to-clear-existing-fields: Do you want to clear existing fields?
initial: Initial
combined-pdf: Combined PDF
document-pdf: Document PDF
zip-archive: Zip
download-signed-pdf: Download Signed PDF
initial-document: Initial Document
draw-initials: Draw Initials
upload-initials: Upload Initials
to-approve: Approve
to-complete: Complete
to-start: Start
fit-zoom-to-page-width: Fit zoom to page width
number-fields: '{number} fields'
with-errors-only: With errors only
user-defined: User Defined
view-details: View Details
name-must-be-no-longer-than-characters: 'Name must be no longer than {length} characters'
do-you-want-to-delete-number-selected-signings-or-only-name: Do you want to delete {number} selected signings or only "{name}"?
do-you-want-to-permanently-delete-number-selected-signings-or-only-name: Do you want to permanently delete {number} selected signings or only "{name}"?
do-you-want-to-delete-number-selected-templates-or-only-name: Do you want to delete {number} selected templates or only "{name}"?
delete-number-selected: Delete {number} Selected
delete-only-this-signing: Delete Only This Signing
delete-templates: Delete Templates
signing-initiator: Signing Initiator
initiator: Initiator
if-you-have-any-questions-please-contact-the-sender: If you have any questions please contact the sender
you-have-completed-your-portion-of-the-electronic-signing-instance: You’ve completed your portion of the Electronic Signing Instance
you-have-previously-completed-your-portion-of-the-electronic-signing-instance: You've previously completed your portion of the Electronic Signing Instance!
you-may-download-progress-pdf-version-of-the-document-below: You may download a progress PDF version of the document(s) below
signing-process-previously-rejected: eSigning Declined
signing-has-been-withdrawn: Signing has been withdrawn
signing-has-been-resent-to-a-new-email-address: Signing has been resent to a new email address
this-signing-has-expired: This signing has expired
please-check-your-email-for-a-new-one: Please check your email for a new one
expiration-date-should-be-in-future: Expiration date should be in future
invitation-successfully-resent: Invitation successfully resent
or: or
company: Company
title: Title
company-address: Company Address
top: Top
middle: Middle
bottom: Bottom
proceed: Proceed
all-previously-signed-signature-images-will-also-be-changed: All previously signed signature images will also be changed
all-previously-signed-images-of-initials-will-also-be-changed: All previously signed images of initials will also be changed
individual: Individual
organization: Organization
do-you-want-to-merge-the-following-contacts: Do you want to merge the following contacts?
overwrite: Overwrite
send-reminder-about-incomplete-signing: Send reminder about incomplete signing
complete-signing: Complete Signing
before-expiry: before expiry
upload-document-files: Upload Document(s)
upload-custom-files: Upload Document(s)
loading: Loading
complete-review: Complete Review
please-complete-review-by-reading-entire-document-first: Please complete review by reading entire document first
the-file-type-you-uploaded-is-corrupted: The type you uploaded is corrupted.
review-is-completed: Review Is Completed
page-image-loading-error: Page image loading error
include-all: Include all
exclude-all: Exclude all
please-read-the-entire-document: Please read the entire document.
permanently-delete: Permanently Delete
permanently-delete-template: Permanently Delete Template
no-records-found: No records found
do-not-send: Do Not Send
layout-is-signatures-initials-and-current-date-fields: Layout is Signatures Initials and Current date fields
remember-my-choice-for-this-contact: Remember my choice for this contact
contact-changes-are-highlighted: Contact changes are highlighted
edit-participant: Edit Participant
edit-role: Edit Role
create-role: Create Role
add-participant: Add Participant
log-in: Log in
enter-sms-code: Enter SMS code
a-verification-code-was-sent-to-phone: A verification code was sent to {phone}
your-phone-number: Your phone number
invalid-code-entered: Invalid code entered
resend-code: Resend Code
select-documents: Select Documents
select-documents-subtitle-creating-template-from-template: Select any document(s) you would like to include in the new template from the list below.<br>Documents can be added/modified in the Template Editor.
select-documents-subtitle-creating-signing-from-template: Select any document(s) you would like to include in the signing from the list below.<br>Documents can be added/modified in the Signing Editor.
select-documents-subtitle-generic: Select any document(s) you would like to include from the list below.<br>Documents can be added/modified in the Editor.
select-roles: Select Roles
select-roles-subtitle-creating-template-from-template: Select any Role(s) you would like to include in the new template from the list below.<br>Roles can be added/modified in the Template Editor.
select-roles-subtitle-creating-signing-from-template: Select any Role(s) you would like to include in the signing from the list below.<br>Roles/Contacts can be added/modified and Contact Groups can be assigned in the Signing Editor.
select-roles-subtitle-generic: Select any roles(s) you would like to include from the list below.<br>Roles can be added/modified in the Editor.
select-contact: Select Contact
select-team: Select Team
save-for-association: Save for Association
save-for-firm: Save for Firm
save-for-office: Save for Office
save-for-personal: Save for Personal Use
save-for-mls: Save for SmartMLS
save-for-team: Save for Team
save-to-personal-clause-library: Save to Personal Clause Library
save-to-office-clause-library: Save to Office Clause Library
save-to-firm-clause-library: Save to Firm Clause Library
save-to-my-mls: Save to SmartMLS
save-to-my-association: Save to My Association
save-to-my-firm: Save to My Firm
save-to-my-office: Save to My Office
save-to-my-team: Save to My Team
save-to-my-favorites: Save to My Favorites
saved-to-my-favorites: Saved to My Favorites
save-to-favorites: Save to Favorites
saved-to-favorites: Saved to Favorites
save-to-favorites-mls: Save to SmartMLS Favorites
saved-to-favorites-mls: Saved to SmartMLS Favorites
save-to-favorites-association: Save to Association Favorites
saved-to-favorites-association: Saved to Association Favorites
save-to-favorites-firm: Save to Firm Favorites
saved-to-favorites-firm: Saved to Firm Favorites
save-to-favorites-office: Save to Office Favorites
saved-to-favorites-office: Saved to Office Favorites
save-to-favorites-team: Save to Team Favorites
saved-to-favorites-team: Saved to Team Favorites
personal-favorites: My Favorites
mls-favorites: SmartMls Favorites
firm-favorites: Firm Favorites
office-favorites: Office Favorites
team-favorites: Team Favorites
most-used-favorites: My Most Used
search-result: Search result
select-some-role: Select Some Role
selected: Selected
distribution: Distribution
restore: Restore
apply-signing-overlay: Apply Signing Overlay
all-fields: All Fields
signature-and-initial-fields-only: Signature and Initial Fields Only
form-only: Form Only (no fields)
other-fields: Other Fields
options: Options
role-options: Role Options
field-options: Field Options
custom-selection: Custom Selection
address: Address
currency: Currency
percentage: Percentage
removed: Removed
existing-contact: Existing Contact
after-merge: After Merge
contact: Contact
signing-expiration-notifications: Signing Expiration Notifications
expiration-notification-day: Expiration Notification Day
before-signing-expiration-date: before signing expiration date
expiration-notification-time: Expiration Notification Time
participant-autofill: Participant Autofill
current-date: Current Date
initiator-textline: Initiator TextLine
initiator-percentage: Initiator Percentage
initiator-currency: Initiator Currency
initiator-date: Initiator Date
initiator-phone: Initiator Phone
initiator-email: Initiator Email
include-a-reminder-every: Include a reminder every
autofill-name: Autofill Name
autofill-email: Autofill Email
autofill-phone: Autofill Phone
autofill-address: Autofill Address
autofill-title: Autofill Title
autofill-company: Autofill Company
participant-fill-in: Participant Fill-in
system-fill-in: Autofill
participant-fill-in-fields: Participant Fill-in Fields
restore-contact: Restore contact
restore-group: Restore group
restore-template: Restore Template
apartment: Apartment
suite: Suite
etc: etc
city: City
state: State
zip-code: Zip-Code
common-fields: Common Fields
fill-in: Fill-in
radio-button: Radio Button
oops-an-error-occurred-please-contact-support: Oops, an error occurred. Please contact support.
this-text-will-be-included-in-an-email-to-the-signing-participants-notifying-them-that-the-signing-has-been-withdrawn: This text will be included in an email to the signing participants notifying them that the signing has been withdrawn
if-you-would-like-your-signers-to-receive-an-email-to-explain-the-update-please-enter-your-comments-here-so-we-can-share-them-please-leave-the-box-blank-if-you-do-not-wish-an-email-to-be-sent: If you would like your signers to receive an email to explain the update, please enter your comments here so we can share them. Please leave the box blank if you do not wish an email to be sent.
withdrawn-as-expired: Withdrawn as expired
are-you-sure-you-want-to-withdrawn-this-signing: Are you sure you want to withdrawn this signing?
are-you-sure-you-want-to-mark-this-signing-as-completed: Are you sure you want to mark this signing as completed?
document-marked-as-completed: Document marked as completed
open-dashboard: Open Dashboard
sender: Sender
expand: Expand
you: You
save-to-contacts: Save to Contacts
use-the-button-below-to-sign-in-and-display-the-signings-the-button-opens-in-a-new-window-once-signed-in-you-can-just-close-this-window-and-go-back: Use the button below to sign in and display the signings. The button opens in a new window. Once signed in, you can just close this window and go back.
application-will-be-updated-in-left-minutes: Application will be updated in {left} minutes
please-ensure-your-work-is-saved-and-be-ready-for-reloading-the-current-page: Please ensure your work is saved and be ready for reloading the current page
close-alert: Close Alert
update-in-progress: Update in Progress
maintenance-in-progress: Maintenance in progress...
please-do-no-reload-page: Please do not reload the page until it's done.
maintenance-begin-in: Maintenance begin in
reloading-page-in: Reloading page in
reload-now: Reload Now
owner: Owner
edit-and-resend: Edit and Resend
tags: Tags
tags-filter: Tags filter
hours: Hours
days: Days
template-description: Template Description
users-count: No Users | {count} User | {count} Users
important-note: Important note
i-accept: I Accept
the: the
consent-text-1: By clicking "Continue", you agree that you have read and access to the terms of the
consent-text-2: and finally the
consent-agent-text-1: By clicking "I Accept", you agree that you have read and access to the terms of the
consent-highlight-text: Please note, this is only accepting these agreements, not accepting anything in the document that you have been asked to sign.
master-terms-of-service: Master terms of service
supplemental-terms-of-service: Supplemental terms of service
privacy-notice: Privacy notice
consumer-consent-policy: Consumer consent policy
it-is-strongly-recommended-to-fill-in-the-contact-phone-number: It is strongly recommended to fill in the contact phone number
linked-fields:
  math: Math
  link: Link
  join: Join (concat)
  unlink: Unlink
  field-name: Field Name
  click-to-add: click to add
  link-to: Link to
  sum-with: Sum with
  join-with: Join with
  is-linked: This is a linked field
  is-a-link-part: This field is selected for link
  is-a-sum: This field contains a SUM
  is-a-sum-element: This field is a part of the SUM
  is-a-join: This field contains a JOIN of other field
  is-a-join-element: This field is a part of the JOIN
  is-a-mirrored-field: This is a mirrored field
retain-any-information-entered-by-the-participants:  Retain any information entered by the participants.
bg-opacity: Background Opacity
mirror: Mirror
save-and-continue: Save and Continue
done: Done
show-label: Show Label
headshot: Headshot
these-fields-can-be-managed-through-your-mls-settings: These fields can be managed through your MLS settings
logo: Logo
broker-logo: Broker Logo
upload-broker-logo: Upload Broker Logo
background: Background
update: Update
create-new: Create New
clear-fields-information: Clear Common Fields
are-you-sure-you-wanted-to-clear-all-fields-information: Are you sure you wanted to clear all fields information?
are-you-sure-you-wanted-to-delete-all-fields: Are you sure you wanted to delete all fields?
delete-all-fields: Delete All Fields
clear-all-fields: Clear All Fields
one-or-more-checkboxes-have-not-been-completed-do-you-still-wish-to-proceed: One or more checkboxes have not been completed. Do you still wish to proceed?
custom-background-color: Custom Background Color
save-this-information-to-the-contact-list: Save this information to the contact list?
update-email-for-this-contact: Update email for this Contact
update-phone-for-this-contact: Update phone for this Contact
this-field-has-custom-background-color: This field has a custom background color
undo-last-field-changes: Undo last field changes
redo-last-field-changes: Redo last field changes
sorry-were-experiencing-issue-please-contact-support-for-help-the-issue-reference-is-ref: Sorry, were’re experiencing an issue. Please contact support for help. The issue reference is {ref}
all-fields-assigned-to-this-participant-will-be-adjusted-to-an-unassigned-role-do-you-wish-to-continue: All fields assigned to this participant will be adjusted to an unassigned role. Do you wish to continue?
settings-page:
  email: Email Settings
  notification: Notification Settings
  instructions: Saved Instructions
  clause-library: Clause Library
email-appearance-preview: Email Appearance Preview
instruction-library: Instruction Library
create-instruction: Create Instruction
edit-instruction: Edit Instruction
saved-instruction: Saved instructions
edit-message-template: Edit Message template
clause-library: Clause Library
create-clause: Create Clause
edit-clause: Edit Clause
permanently-delete-clause: Permanently Delete Clause
no-clauses-found: No Clauses Found
no-instructions-found: No Instructions Found
settings-email: Email Settings
settings-notification: Notification Settings
sms-authentication: SMS Authentication
to-add: Add
IMPORTANT-NOTICE: IMPORTANT NOTICE
once: Once
ALL: ALL
system: System
one-or-more-signatures-or-initials-do-not-require-an-action-by-the-signer-do-you-still-wish-to-proceed: One or more Signatures or Initials do not require an action by the Signer. Do you still wish to proceed?
one-or-more-autofill-fields-are-empty-do-you-still-wish-to-proceed: One or more Autofill fields are empty. Do you still wish to proceed?
participants-have-completed-their-respective-portions-of-the-electronic-signing-instance-esi-a-completed-pdf-version-including-esi-certificate-will-be-distributed-to-all-participants-via-email:  Participants have completed their respective portions of the Electronic Signing Instance (ESI), a completed PDF version, including ESI Certificate, will be distributed to all Participants via email
please-be-certain-to-download-and-retain-a-copy-for-your-records: Please be certain to download and retain a copy for your records
sent-within-eSigning-invitation: sent within eSigning Invitation
sent-within-eSigning-distribution: sent within eSigning Distribution
multiple-template-warning: Multiple Template Warning
adding-more-than-one-template-removes-all-associated-signing-flow-formatting-participant-roles-will-be-assigned-to-stage1-and-the-signing-order-will-be-designated-as-any-and-instructions-will-be-removed: 'Adding more than one (1) Template removes all associated Signing Flow formatting (i.e.: Participant Roles will be assigned to “Stage 1” and the Signing Order will be designated as “Any”; and Instructions will be removed).'
you-should-edit-the-signing-flow-as-necessary-to-ensure-your-desired-workflow-prior-to-clicking: You should edit the Signing Flow as necessary to ensure your desired workflow, prior to clicking
document-replacement: Document Replacement
replace: Replace
apply: Apply
this-text-will-be-included-in-an-email-to-the-recipients-of-the-forwarded-signing: This text will be included in an email to the recipients of the forwarded signing
the-number-of-pages-in-the-document-does-not-match-the-number-of-pages-in-the-overlay-please-review-all-fields-to-ensure-they-are-correct: The number of pages in this Document does not match the number of pages in the original Document. Please review all fields to ensure they are correct.
no-expiration-notice-emails-will-be-delivered-notification-settings-can-be-managed-within-your-settings:  No Expiration Notice emails will be delivered. Notification Settings can be managed within your Settings.
you-do-not-have-access-to-this-directory: You do not have access to this directory
there-may-be-delays-in-SMS-delivery-please-wait-before-hitting-resend-code: There may be delays in SMS delivery. Please wait {time} before hitting Resend Code.
all-fields-assigned-to-deleted-participants-will-be-removed.-do-you-wish-to-continue: All fields assigned to deleted participants will be removed. Do you wish to continue?
signing-link-has-expired: Signing link has expired
you-may-request-a-new-link-to-ve-sent-to-your-email-by-clicking-below: You may request a new link to ve sent to your email by clicking below.
request-link: Request link
new-link-will-be-sent-to-your-email: New link will be sent to your email.
all-fields-assigned-to-participants-will-be-removed.-do-you-wish-to-continue: All fields assigned to this participant will be removed. Do you wish to continue?
all-fields-assigned-to-participants-will-be-removed.-are-you-sure-you-want-to-delete-stage-name: All fields assigned to participants in this stage will be removed. Are you sure you want to delete stage "{name}"?
the-electronic-signing-instance-has-been-completed-by-all-participants: The Electronic Signing Instance has been completed by all participants!
download-a-pdf-version-below: Download a PDF version below.
the-uploaded-file-size-should-not-be-greater-than: 'The uploaded file size should not be greater than {maxSizeMb}Mb.'
your-session-has-been-expired-please-re-login-to-continue-your-work:  Your session has been expired. Please re-login to continue your work.
re-login: Re-login
for: for